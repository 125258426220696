import { Clock } from "./Clock";
import { MatchID } from "./MatchID";
import { Name } from "./Name";
import { Versus } from "./Versus";
import { Card } from "./Card";
import { Lineup } from "./Lineup";
import { PlayerScored } from "./PlayerScored";
import { Substitution } from "./Substitution";
import { Score } from "./Score";
import { Penalties } from "./Penalties";
import { TwoSubstitution } from "./TwoSubstitution";
import { ThreeSubstitution } from "./ThreeSubstitution";
import { Formation } from "./Formation";
import { TeamTicker } from "./TeamTicker";
import { FFStats } from "./FFStats";
import { POTM } from "./POTM";
import { TeamStat } from "./TeamStat";
import { Group } from "./Group";
import { Fixtures } from "./Fixtures";
import { TeamArrival } from "./TeamArrival";
import { TeamSponsor } from "./TeamSponsor";
import { Referee } from "./Referee";
import { FFSponsor } from "./FFSponsor";
import { HTFTBug } from "./HT_FT_Bug";
import { Standings } from "./Standings";
import { Attendance } from "./Attendance";
import { Commentators } from "./Commentators";
import { GoalUpdate } from "./GoalUpdate";
import { TouchMap } from "./TouchMap";

export default function graphics({
  preview,
  animate,
  update,
  animateOff,
  triggerAnimation,
  clearPreview,
}) {
  return [
    new MatchID({ preview, animate, update, animateOff }),
    new Versus({ preview, animate, update, animateOff, triggerAnimation }),
    new Clock({ preview, animate, update, animateOff, triggerAnimation }),
    new Name({ preview, animate, update, animateOff, triggerAnimation }),
    new Card({ preview, animate, update, animateOff, triggerAnimation }),
    new Lineup({ preview, animate, update, animateOff, triggerAnimation }),
    new Formation({ preview, animate, update, animateOff, triggerAnimation }),
    new PlayerScored({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
    }),
    new Substitution({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
    }),
    new Score({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
    }),
    new Penalties({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
    }),
    new TwoSubstitution({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new ThreeSubstitution({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new TeamTicker({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new FFStats({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new POTM({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new TeamStat({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new Group({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new Fixtures({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new TeamArrival({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new TeamSponsor({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new Referee({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new FFSponsor({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new HTFTBug({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new Standings({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new Attendance({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new Commentators({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new GoalUpdate({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new TouchMap({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
  ];
}
