import React, { useContext } from "react";
import styled from "styled-components";
import { APIContext } from "../../contexts/APIContext";
import { DataContext } from "../../contexts/DataContext";
import { GraphicsContext } from "../../contexts/GraphicsContext";
import { UIContext } from "../../contexts/UIContext";

const Main = styled.div`
  display: flex;
`;

const Team = styled.div`
  display: flex;
  width: 40%;
  flex-direction: column;
`;

const Button = styled.div`
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  align-items: center;
  display: flex;

  background-color: ${(props) =>
    props.green ? "#0adb93" : props.red ? "#db0a41" : "#181d25"};
  color: ${(props) => (props.green ? "#1d1d1d" : "")};
  width: 80%;
  justify-content: center;
  font-size: 15px;
  margin-top: 0.5em;
  padding: 0.5em;
  height: 80px;
  margin-bottom: 50px;
`;

const Centre = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  align-items: center;
  font-size: 34px;
`;

const Penalty = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
`;

const Hit = styled.div`
  border-radius: 7px;
  width: 90%;
  height: 50px;
  background-color: ${(props) => (props.selected ? "#0adb93" : "")};
  border: 2px solid #0adb93;
  margin: 0.5em;
  opacity: ${(props) => (props.selected ? 1 : 0.5)};
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;

const Miss = styled.div`
  border-radius: 7px;
  width: 90%;
  height: 50px;
  background-color: ${(props) => (props.selected ? "#db0a41" : "")};
  border: 2px solid #db0a41;
  margin: 0.5em;
  opacity: ${(props) => (props.selected ? 1 : 0.5)};
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;

const Empty = styled.div`
  border-radius: 7px;
  width: 90%;
  height: 50px;
  background-color: ${(props) => (props.selected ? "#202731" : "")};
  margin: 0.5em;
  opacity: ${(props) => (props.selected ? 1 : 0.5)};
  border: 2px solid #202731;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #898989;
  padding-top: 0.5em;
  padding-bottom: 1em;
`;
export default function Penalties({ game, teams }) {
  const { previewGraphic, updateGraphic, animateOffGraphic } =
    useContext(GraphicsContext);
  const { updateGame } = useContext(APIContext);
  const { homePenalties, setHomePenalties, awayPenalties, setAwayPenalties } =
    useContext(UIContext);

  function updatePenalty({ team = "home", index, type }) {
    let newGame = { ...game };
    if (team === "home") {
      let newPenalties = [...(homePenalties || [])];

      newPenalties = newPenalties.filter((item) => item.index !== index);
      newPenalties.push({ type: type, index: index, time: Date.now() });
      setHomePenalties(newPenalties);
      debugger;
      updateGame({ id: game._id, [team + "_penalties"]: newPenalties });

      newGame[team + "_penalties"] = newPenalties;
    } else if (team === "away") {
      let newPenalties = [...(awayPenalties || [])];

      newPenalties = newPenalties.filter((item) => item.index !== index);
      newPenalties.push({ type: type, index: index, time: Date.now() });
      setAwayPenalties(newPenalties);

      updateGame({ id: game._id, [team + "_penalties"]: newPenalties });

      newGame[team + "_penalties"] = newPenalties;
    }
    updateGraphic("Penalties", {
      game: newGame,
    });
  }

  return (
    <Main>
      <Team>
        <Row>
          {new Array(5).fill(1).map((item, index) => {
            let penalty =
              homePenalties &&
              homePenalties.find((pen) => pen && pen.index === index);

            return (
              <Penalty>
                <Hit
                  selected={penalty && penalty.type === 1}
                  onClick={() => {
                    updatePenalty({ team: "home", index: index, type: 1 });
                  }}
                ></Hit>
                <Miss
                  selected={penalty && penalty.type === 0}
                  onClick={() => {
                    updatePenalty({ team: "home", index: index, type: 0 });
                  }}
                ></Miss>
                <Empty
                  selected={
                    !penalty || (penalty.type !== 1 && penalty.type !== 0)
                  }
                  onClick={() => {
                    updatePenalty({ team: "home", index: index, type: -1 });
                  }}
                ></Empty>
              </Penalty>
            );
          })}
        </Row>
        <Row>
          {new Array(5).fill(1).map((item, i) => {
            let index = i + 5;
            let penalty =
              homePenalties &&
              homePenalties.find((pen) => pen && pen.index === index);
            return (
              <Penalty>
                <Hit
                  selected={penalty && penalty.type === 1}
                  onClick={() => {
                    updatePenalty({ team: "home", index: index, type: 1 });
                  }}
                ></Hit>
                <Miss
                  selected={penalty && penalty.type === 0}
                  onClick={() => {
                    updatePenalty({ team: "home", index: index, type: 0 });
                  }}
                ></Miss>
                <Empty
                  selected={
                    !penalty || (penalty.type !== 1 && penalty.type !== 0)
                  }
                  onClick={() => {
                    updatePenalty({ team: "home", index: index, type: -1 });
                  }}
                ></Empty>
              </Penalty>
            );
          })}
        </Row>
        <Row>
          {new Array(5).fill(1).map((item, i) => {
            let index = i + 10;
            let penalty =
              homePenalties &&
              homePenalties.find((pen) => pen && pen.index === index);
            return (
              <Penalty>
                <Hit
                  selected={penalty && penalty.type === 1}
                  onClick={() => {
                    updatePenalty({ team: "home", index: index, type: 1 });
                  }}
                ></Hit>
                <Miss
                  selected={penalty && penalty.type === 0}
                  onClick={() => {
                    updatePenalty({ team: "home", index: index, type: 0 });
                  }}
                ></Miss>
                <Empty
                  selected={
                    !penalty || (penalty.type !== 1 && penalty.type !== 0)
                  }
                  onClick={() => {
                    updatePenalty({ team: "home", index: index, type: -1 });
                  }}
                ></Empty>
              </Penalty>
            );
          })}
        </Row>
      </Team>
      <Centre>
        {(homePenalties &&
          homePenalties.reduce((prev, curr) => {
            if (curr.type === 1) {
              return prev + 1;
            }
            return prev;
          }, 0)) ||
          0}{" "}
        -{" "}
        {(awayPenalties &&
          awayPenalties.reduce((prev, curr) => {
            if (curr.type === 1) {
              return prev + 1;
            }
            return prev;
          }, 0)) ||
          0}
        <Button
          onClick={() => {
            previewGraphic("Penalties", {
              game: {
                ...game,
                home_penalties: homePenalties,
                away_penalties: awayPenalties,
              },
              teams: teams,
            });
          }}
        >
          PVW Penalty Graphic
        </Button>
        <Button
          red
          onClick={() => {
            animateOffGraphic("Penalties", {
              game: {
                ...game,
                home_penalties: homePenalties,
                away_penalties: awayPenalties,
              },
              teams: teams,
            });
          }}
        >
          Animate Out Penalty Graphic
        </Button>
      </Centre>
      <Team>
        <Row>
          {new Array(5).fill(1).map((item, index) => {
            let penalty =
              awayPenalties &&
              awayPenalties.find((pen) => pen && pen.index === index);
            return (
              <Penalty>
                <Hit
                  selected={penalty && penalty.type === 1}
                  onClick={() => {
                    updatePenalty({ team: "away", index: index, type: 1 });
                  }}
                ></Hit>
                <Miss
                  selected={penalty && penalty.type === 0}
                  onClick={() => {
                    updatePenalty({ team: "away", index: index, type: 0 });
                  }}
                ></Miss>
                <Empty
                  selected={
                    !penalty || (penalty.type !== 1 && penalty.type !== 0)
                  }
                  onClick={() => {
                    updatePenalty({ team: "away", index: index, type: -1 });
                  }}
                ></Empty>
              </Penalty>
            );
          })}
        </Row>
        <Row>
          {new Array(5).fill(1).map((item, i) => {
            let index = i + 5;
            let penalty =
              awayPenalties &&
              awayPenalties.find((pen) => pen && pen.index === index);
            return (
              <Penalty>
                <Hit
                  selected={penalty && penalty.type === 1}
                  onClick={() => {
                    updatePenalty({ team: "away", index: index, type: 1 });
                  }}
                ></Hit>
                <Miss
                  selected={penalty && penalty.type === 0}
                  onClick={() => {
                    updatePenalty({ team: "away", index: index, type: 0 });
                  }}
                ></Miss>
                <Empty
                  selected={
                    !penalty || (penalty.type !== 1 && penalty.type !== 0)
                  }
                  onClick={() => {
                    updatePenalty({ team: "away", index: index, type: -1 });
                  }}
                ></Empty>
              </Penalty>
            );
          })}
        </Row>
        <Row>
          {new Array(5).fill(1).map((item, i) => {
            let index = i + 10;
            let penalty =
              awayPenalties &&
              awayPenalties.find((pen) => pen && pen.index === index);
            return (
              <Penalty>
                <Hit
                  selected={penalty && penalty.type === 1}
                  onClick={() => {
                    updatePenalty({ team: "away", index: index, type: 1 });
                  }}
                ></Hit>
                <Miss
                  selected={penalty && penalty.type === 0}
                  onClick={() => {
                    updatePenalty({ team: "away", index: index, type: 0 });
                  }}
                ></Miss>
                <Empty
                  selected={
                    !penalty || (penalty.type !== 1 && penalty.type !== 0)
                  }
                  onClick={() => {
                    updatePenalty({ team: "away", index: index, type: -1 });
                  }}
                ></Empty>
              </Penalty>
            );
          })}
        </Row>
      </Team>
    </Main>
  );
}
