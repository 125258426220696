import React, { useContext, useState } from "react";
import styled from "styled-components";
import Stat from "./Stat";
import ActionAreas from "./ActionAreas";
import { GraphicsContext } from "../../contexts/GraphicsContext";
import { DataContext } from "../../contexts/DataContext";

const defaultStats = {
  possessionPercentage: 0,
  touches: 0,
  interception: 0,
  lostCorners: 0,
  fkFoulWon: 0,
  totalPass: 0,
  accuratePass: 0,
  passAccuracy: 0,
  totalPassInclCross: 0,
  totalTackle: 0,
  wonCorners: 0,
  cornerTaken: 0,
  totalCross: 0,
  totalThrows: 0,
  fkFoulLost: 0,
  totalYellowCard: 0,
  totalYellowCardOnPitch: 0,
  totalScoringAtt: 0,
  shotOffTarget: 0,
  shotAccuracy: 0,
  attRfTotal: 0,
  attIbox: 0,
  blockedScoringAtt: 0,
  attHdTotal: 0,
  wonTackle: 0,
  attLfTotal: 0,
  totalOffside: 0,
  ontargetScoringAtt: 0,
  goalKicks: 0,
  attFreekickTotal: 0,
  secondYellow: 0,
  totalRedCard: 0,
  secondYellowOnPitch: 0,
  totalRedCardOnPitch: 0,
  penaltyWon: 0,
  goals: 0,
  attRfGoal: 0,
  penaltyTaken: 0,
  attIboxGoal: 0,
  assistPenaltyWon: 0,
};
const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  font-size: 0.8em;
`;
const Row = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 1em;
  font-weight: 100;
  align-items: center;
`;
const Button = styled.div`
  padding: 0.5em;
  min-width: 50px;
  display: flex;
  background-color: ${(props) => (props.selected ? "#db0a41" : "#202731")};
  justify-content: center;
  align-items: center;
  height: 55%;
  border-radius: 4px;
  margin: 0.5em;
  box-sizing: border-box;
  :hover {
    background-color: #db0a41;
    cursor: pointer;
  }
`;
export default function TeamStats({ game }) {
  return (
    <Main>
      <Row>
        <Stat
          game={game}
          category={"Attempts"}
          stat={{
            home: game?.opta_team_stats?.home?.find(
              (stat) => stat.type === "totalScoringAtt"
            )?.value,
            away: game?.opta_team_stats?.away?.find(
              (stat) => stat.type === "totalScoringAtt"
            )?.value,
          }}
        />
      </Row>
      <Row>
        <Stat
          game={game}
          category={"On Target"}
          stat={{
            home: game?.opta_team_stats?.home?.find(
              (stat) => stat.type === "ontargetScoringAtt"
            )?.value,
            away: game?.opta_team_stats?.away?.find(
              (stat) => stat.type === "ontargetScoringAtt"
            )?.value,
          }}
        />
      </Row>

      <Row>
        <Stat
          game={game}
          category={"Corners"}
          stat={{
            home: game?.opta_team_stats?.home?.find(
              (stat) => stat.type === "wonCorners"
            )?.value,
            away: game?.opta_team_stats?.away?.find(
              (stat) => stat.type === "wonCorners"
            )?.value,
          }}
        />
      </Row>
      <Row>
        <Stat
          game={game}
          category={"Offsides"}
          stat={{
            home: game?.opta_team_stats?.home?.find(
              (stat) => stat.type === "totalOffside"
            )?.value,
            away: game?.opta_team_stats?.away?.find(
              (stat) => stat.type === "totalOffside"
            )?.value,
          }}
        />
      </Row>
      <Row>
        <Stat
          game={game}
          category={"Fouls"}
          stat={{
            home: game?.opta_team_stats?.home?.find(
              (stat) => stat.type === "fkFoulLost"
            )?.value,
            away: game?.opta_team_stats?.away?.find(
              (stat) => stat.type === "fkFoulLost"
            )?.value,
          }}
        />
      </Row>
      <Row>
        <Stat
          game={game}
          category={"Yellow Cards"}
          stat={{
            home: game?.opta_team_stats?.home?.find(
              (stat) => stat.type === "totalYellowCard"
            )?.value,
            away: game?.opta_team_stats?.away?.find(
              (stat) => stat.type === "totalYellowCard"
            )?.value,
          }}
        />
      </Row>
      <Row>
        <Stat
          game={game}
          category={"Red Cards"}
          stat={{
            home: game?.opta_team_stats?.home?.find(
              (stat) => stat.type === "totalRedCard"
            )?.value,
            away: game?.opta_team_stats?.away?.find(
              (stat) => stat.type === "totalRedCard"
            )?.value,
          }}
        />
      </Row>
    </Main>
  );
}
