import moment from "moment";

export class Standings {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "Standings";
    this.scene = "League Table";
    this.data = [];
    this.page = 1;
  }

  preview({ options }) {
    if (!options.page) {
      this.page = 1;
    }
    this.options = options;
    this.onAir = false;

    this.data = [];
    this.data.push({
      name: "Page",
      value: { text: this.page || "" },
    });
    this.data.push({
      name: "Competition",
      value: { text: options.game?.competition?.name || "" },
    });
    this.data.push({
      name: "Competition Colour",
      value: { colour: options.game?.competition?.colour || "" },
    });
    this.data.push({
      name: "Competition Badge",
      value: { image: "${user}" + options.game?.competition?.badge || "" },
    });

    options.standings = JSON.parse(JSON.stringify(options?.standings));
    new Array(30)?.fill(1)?.map((_, index) => {
      let team = options?.standings?.teams?.[index];

      let row = index + 1;

      this.data.push({
        name: "Team Name " + row,
        value: { text: team?.team?.name || "" },
      });
      this.data.push({
        name: "Team Short Name " + row,
        value: { text: team?.team?.short_name || "" },
      });
      this.data.push({
        name: "Team Badge " + row,
        value: { image: team?.team?.variables?.badge || "" },
      });
      this.data.push({
        name: "Team Secondary Badge " + row,
        value: { image: team?.team?.variables?.alt_badge || "" },
      });
      this.data.push({
        name: "Team Video Badge " + row,
        value: { video: team?.team?.variables?.video_badge || "" },
      });
      this.data.push({
        name: "Team Rank " + row,
        value: { text: team?.rank ?? "" },
      });
      this.data.push({
        name: "Team Rank Status " + row,
        value: { text: team?.rank_status ?? "" },
      });
      this.data.push({
        name: "Team Points " + row,
        value: { text: team?.points ?? "" },
      });
      this.data.push({
        name: "Team Games Played " + row,
        value: { text: team?.matches_played ?? "" },
      });
      this.data.push({
        name: "Team Games Won " + row,
        value: { text: team?.matches_won ?? "" },
      });
      this.data.push({
        name: "Team Games Lost " + row,
        value: { text: team?.matches_lost ?? "" },
      });
      this.data.push({
        name: "Team Games Drawn " + row,
        value: { text: team?.matches_drawn ?? "" },
      });
      this.data.push({
        name: "Team Goal Difference " + row,
        value: { text: team?.goal_difference ?? "" },
      });
      this.data.push({
        name: "Team Goals For " + row,
        value: { text: team?.goals_for ?? "" },
      });
      this.data.push({
        name: "Team Goals Against " + row,
        value: { text: team?.goals_against ?? "" },
      });
    });

    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.page++;
    this.options.page = this.page;
    this.onAir = true;
    this.sendAnimate(this.scene, this.data);
    this.preview({ options: this.options });
  }
  animateOff({ options }) {
    this.page = 1;
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
